import React, { InputHTMLAttributes } from 'react'

interface IProps {
  name: string
  label: string
}

const TextArea: React.FunctionComponent<IProps & InputHTMLAttributes<HTMLTextAreaElement>> = props => {
  return (
    <div className="field-wrapper">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <textarea {...props} id={props.name} />
    </div>
  )
}

export default TextArea
