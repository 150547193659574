import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { animated, useSpring } from 'react-spring'
import logo from '../../images/logo.png'
// import SectionWrapper from '../../wrapperComponents/SectionWrapper'
import './Header.scss'

interface IProps {
  // setActive: () => void
}

const Header: React.FunctionComponent<IProps> = (props) => {
  const animation = useSpring({
    from: { transform: 'translate3d(0,-150px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  })

  return (
    // <SectionWrapper setActive={props.setActive} className="header">
    <div className="view header">
      <animated.img style={animation} src={logo} title="Stam codes logo" alt="Stam codes logo" />
      <h1>Stam</h1>
      <h2>codes</h2>

      <div className="arrow-down" onClick={scrollToNext}>
        <MdKeyboardArrowDown />
      </div>
      {/* </SectionWrapper> */}
    </div>
  )

  function scrollToNext() {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }
}

export default Header
