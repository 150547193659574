import React from 'react'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import gitLogo from '../../images/Logos/git-original.svg'
import graphqlLogo from '../../images/Logos/graphql-plain.svg'
import tailwindLogo from '../../images/Logos/tailwindcss-plain.svg'
import typescriptLogo from '../../images/Logos/typescript-plain.svg'
import reactLogo from '../../images/Logos/react-original.svg'
import nextLogo from '../../images/Logos/nextjs-original.svg'

import './Services.scss'

interface IProps {
  // setActive: () => void
}

const Services: React.FunctionComponent<IProps & WithTranslation> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="view">
      <div className="text-block">
        <h2>{t('services.title')}</h2>
        <p>{t('services.intro')}</p>
        <p>{t('services.main')}</p>
      </div>

      <div className="services-icons">
        <div className="service-wrapper">
          <img src={reactLogo} title="React icon" alt="React icon" />
          <h3>React</h3>
        </div>
        <div className="service-wrapper">
          <img src={typescriptLogo} title="TypeScript icon" alt="TypeScript icon" />
          <h3>TypeScript</h3>
        </div>
        <div className="service-wrapper">
          <img src={nextLogo} title="Nextjs icon" alt="Nextjs icon" />
          <h3>Next.js</h3>
        </div>
        <div className="service-wrapper">
          <img src={tailwindLogo} title="Tailwind icon" alt="Tailwind icon" />
          <h3>Tailwind</h3>
        </div>
        <div className="service-wrapper">
          <img src={graphqlLogo} title="GraphQL icon" alt="GraphQL icon" />
          <h3>GraphQL</h3>
        </div>
        <div className="service-wrapper">
          <img src={gitLogo} title="Git icon" alt="Git icon" />
          <h3>Git</h3>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Services)
