import React from 'react'
import './Tabs.scss'

interface IProps {
  tabs: Array<{ title: string; icon: string; active: boolean }>
  setActiveTab: (activeTab: number) => void
}

const Tabs: React.FunctionComponent<IProps> = ({ tabs, setActiveTab }) => {
  return (
    <div className="tabs">
      {tabs.map(({ title, icon, active }, index) => {
        return (
          <div key={title} className="tab" onClick={() => setActiveTab(index)}>
            <img src={icon} alt={title} title={title} className={active ? 'active' : ''} />
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
