import React, { useState } from 'react'
import { animated, useSprings } from 'react-spring'
import './Projects.scss'
import Tabs from './tabs/Tabs'

interface IProps {}

interface Tab {
  icon: string
  type: string
  title: string
  description: string
  url?: string
}

const Projects: React.FunctionComponent<IProps> = (props) => {
  const projects = [
    {
      imageUrl: require('../../images/endpointy.png'),
      align: 'RIGHT',
      tabs: [
        {
          icon: require('../../images/icon-project.png'),
          type: 'Project',
          title: 'Endpointy.io',
          description:
            'My first official SaaS product. Building a solution for my own problem and helping others in the process is a great motivator. With endpointy.io your mock API is generated in just a few clicks, it will auto generated the RESTfull endpoints you need.',
          url: 'http://endpointy.io',
        },
        {
          icon: require('../../images/icon-config.png'),
          type: 'Technical specs',
          title: 'Next.js application build with typescript',
          description:
            'I build the front-end and back-end using Next.js, Typescript and Tailwind as the base. It was a fun challenge to include the back-end endpoints in the Next.js api, it ended up working really smooth. To not re-invent the wheel, I used SupaBase for a stable authentication and database flow.',
        },
        {
          icon: require('../../images/icon-review.png'),
          type: 'Review',
          title: 'Christopher Clemmons',
          description: "This is very nice! Something that's been needed for a while. Nice work",
        },
      ] as Tab[],
    },
    // {
    //   align: 'RIGHT',
    //   imageUrl: require('../../images/interact.jpg'),
    //   tabs: [
    //     {
    //       icon: require('../../images/icon-project.png'),
    //       type: 'Project',
    //       title: 'interact!',
    //       description:
    //         'Clinical pharmacology is the study of drugs in humans and their safe and effective use. Interaction occurs between drugs and processes within the body, but also between different drugs or therapies.',
    //       url: 'https://www.interact-clinpharm.com/',
    //     },
    //     {
    //       icon: require('../../images/icon-config.png'),
    //       type: 'Technical specs',
    //       title: 'React application, with headless Wordpress',
    //       description:
    //         'This full response website is made with React. The React application is build with typescript that is loading in the data from a headless Wordpress. The customer can change the data via the custom made Wordpress theme without having to be limited by the Wordpress usage in the customer side website.',
    //     },
    //     // {
    //     //   icon: require('../../images/icon-review.png'),
    //     //   type: 'Review',
    //     //   title: 'By interact!',
    //     //   description: 'Coming soon'
    //     // }
    //   ] as Tab[],
    // },
    // {
    //   imageUrl: require('../../images/hospice.jpg'),
    //   align: 'LEFT',
    //   tabs: [
    //     {
    //       icon: require('../../images/icon-project.png'),
    //       type: 'Project',
    //       title: 'Hospice de Margriet',
    //       description:
    //         'Hospice de Margriet is a hospice located in Vlaardingen, where they offers care, comfort, and quality of life for a person who is approaching the end of life.',
    //       url: 'http://hospicedemargriet.nl/',
    //     },
    //     {
    //       icon: require('../../images/icon-config.png'),
    //       type: 'Technical specs',
    //       title: 'Wordpress with custom theme',
    //       description:
    //         'The initial website was build without a CMS, but to more easily manage the website Wordpress is added. The custom theme is fully re-designed in 2022.',
    //     },
    //     // {
    //     //   icon: require('../../images/icon-review.png'),
    //     //   type: 'Review',
    //     //   title: 'By interact!',
    //     //   description: 'Coming soon'
    //     // }
    //   ] as Tab[],
    // },
  ]

  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      {projects.map(({ align, tabs, imageUrl }, index) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const springs = useSprings(
          tabs.length,
          tabs.map((item, index) => ({
            opacity: index === activeTab ? 1 : 0,
            display: index === activeTab ? 'block' : 'none',
          }))
        )

        return (
          <div
            className="view project"
            style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            key={index}
          >
            <div className={`project-inner ${align === 'LEFT' ? 'align-left' : ''}`}>
              <Tabs
                tabs={tabs.map((tab, tabIndex) => ({
                  icon: tab.icon,
                  title: tab.type,
                  active: tabIndex === activeTab,
                }))}
                setActiveTab={setActiveTab}
              />
              <div className="project-info-box">
                {springs.map((props, index) => {
                  const { type, title, description, url } = tabs[index]
                  return (
                    <animated.div key={index} style={props}>
                      {type ? <h2>{type}</h2> : null}
                      <h3>{title}</h3>
                      <p>{description}</p>
                      {url ? (
                        <a className="button" href={url} title={title} target="blank">
                          Visit website
                        </a>
                      ) : null}
                    </animated.div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Projects
