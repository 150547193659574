import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import TextArea from '../formElements/textArea/TextArea'
import TextField from '../formElements/textField/TextField'
import './Contact.scss'

const Contact: React.FunctionComponent<{}> = () => {
  const email = 'hello@stamcodes.nl'
  // const phone = '+31642656561'
  const linkedin = 'linkedin.com/in/babette-stam/'
  const instagram = 'instagram.com/babsstam/'
  const twitter = 'x.com/babs_codes'
  const { t } = useTranslation()

  return (
    <div className="view contact">
      <div className="row">
        <div className="text-block">
          <h2>{t('contact.title')}</h2>
          <p>{t('contact.intro')}</p>
          <div>
            <div className="label-wrapper">
              <label>
                <i className="fa fa-envelope"></i>
              </label>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className="label-wrapper">
              <label>
                <i className="fab fa-linkedin" />
              </label>
              <a target="_blank" href={`https://www.${linkedin}`} rel="noreferrer">
                {linkedin}
              </a>
            </div>
            <div className="label-wrapper">
              <label>
                <i className="fab fa-instagram" />
              </label>
              <a href={`https://${instagram}`} className="insta-url" target="_blank" rel="noreferrer">
                {instagram}
              </a>
            </div>
            <div className="label-wrapper">
              <label>
                <i className="fab fa-twitter" />
              </label>
              <a href={`https://${twitter}`} className="insta-url" target="blank">
                {twitter}
              </a>
            </div>
          </div>
          <p>{t('contact.use_contact_form')}</p>
        </div>
        <div className="text-block contact-form">
          <form action="https://formspree.io/f/xzzpqgew" method="POST">
            <TextField type="text" name="name" label={t('contact.name')} />
            <TextField type="text" name="email" label={t('contact.email')} />
            <TextArea type="text" name="message" label={t('contact.message')} />
            <button type="submit">{t('contact.submit')}</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Contact as any)
