import React from 'react'
import logo from '../../images/logo.png'

interface IProps {}

const Maintenance: React.FunctionComponent<IProps> = props => {
  return (
    <div>
      <div className="view">
        <div className="header">
          <img src={logo} title="Stam codes logo" alt="Stam codes logo" />
          <h1>Stam</h1>
          <h2>codes</h2>
        </div>
      </div>
      <div className="view">
        <div className="text-block">
          If you have a Wordpress website, we recommend keeping it secure. If you run behind on some security updates,
          it’s very likely that hackers will steal important data from your website. At Stam Codes we offer two
          Wordpress Maintenance packages:
        </div>
        <div className="flex">
          <div className="maintenance-block" />
          <div className="maintenance-block" />
        </div>
      </div>
    </div>
  )
}

export default Maintenance
