import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router } from 'react-router'
import App from './pages/app/App'
import Maintenance from './pages/maintenance/Maintenance'
import * as serviceWorker from './serviceWorker'
import './styles/defaults.scss'
import './translations/i18n'

const browserHistory = createBrowserHistory()

ReactDOM.render(
  <Router  history={browserHistory}>
    <Route exact path="/maintenance" component={Maintenance} />
    <Route exact path="/" component={App}/>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
