import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import json from '../translations/en_EN.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: json
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

  export default i18n