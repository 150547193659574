import React, { InputHTMLAttributes } from 'react'

interface IProps {
  name: string
  label: string
}

const TextField: React.FunctionComponent<IProps & InputHTMLAttributes<HTMLInputElement>> = props => {
  return (
    <div className="field-wrapper">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <input {...props} id={props.name}/>
    </div>
  )
}

export default TextField
