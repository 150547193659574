import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
// import Navigation from '../../components/navigation/Navigation'
import Projects from '../../components/projects/Projects'
import Services from '../../components/services/Services'

const App: React.FC = () => {
  const [ready, setReady] = useState(false)
  const props = useSpring({ opacity: ready ? 1 : 0 })
  // const [isLightNav, setIsLightNav] = useState(true)

  useEffect(() => {
    setReady(true)
  }, [])

  return (
    <animated.div style={props}>
      {/* <Navigation isLightNav={isLightNav} /> */}

      <Header />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </animated.div>
  )
}

export default App
