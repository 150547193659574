import React from 'react'
import './Footer.scss'

interface IProps {}

const Footer: React.FunctionComponent<IProps> = (props) => {
  return <footer className="footer">© {new Date().getFullYear()} Stam codes. All rights reserved.</footer>
}

export default Footer
